import React from 'react';

import Intro from 'components/Intro';
import PrivacyAccordion from 'components/InfoAccordion';
import CookiesPolicy from 'components/CookiesPolicy';
import { Footer, Head, Header } from 'landing/components';

import { Main } from 'landing/ui';

import data from './data.json';

export default function ProcessingOfPersonalData() {
  return (
    <>
      <Head {...data.head} />
      <Header isHeaderFixed />
      <Intro {...data.intro} page="internal" />
      <Main marginDisable>
        <PrivacyAccordion {...data.accordion} />
      </Main>
      <Footer />
      <CookiesPolicy />
    </>
  );
}
